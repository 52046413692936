import React from "react"

import Layout from "../components/layout-alt"
import SEO from "../components/seo"

const NoAccessPage = () => (
  <Layout>
    <SEO title="403: No access" />
    <main>
      <div style={{ margin: "3rem" }}>
        <h1>403: No access</h1>
        <p>You do not have permission to view the page...</p>
      </div>
    </main>
  </Layout>
)

export default NoAccessPage
